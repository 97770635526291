import { computed } from 'vue'
import { useActiveFacility } from '~/composables/use-active-facility'

export function isSfrc() {
    return false
    // const activeFacility = useActiveFacility()
    // return activeFacility.value.organization.code.startsWith('sfrc')
}

export function isApp() {
    const activeFacility = useActiveFacility()
    return computed(() => {
        return activeFacility.value.organization.code.startsWith(
            'portal.redpointhq.com'
        )
    })
}

export function isLocalStorageTestMode() {
    if (import.meta.client) {
        return sessionStorage.getItem('redpoint-hq-test-mode') === 'enabled'
    } else {
        return false
    }
}

export function isStaging() {
    const activeFacility = useActiveFacility()
    return computed(() => {
        return (
            activeFacility.value.organization.code.startsWith(
                'ewtest.rphq.com'
            ) ||
            activeFacility.value.organization.code.startsWith(
                'tradrocksportal.rphq.com'
            )
        )
    })
}

export function isDemo() {
    const activeFacility = useActiveFacility()
    return computed(() =>
        activeFacility.value.organization.code.startsWith('demoportal.rphq.com')
    )
}

export function isEdgeworksProd() {
    const activeFacility = useActiveFacility()
    return computed(() =>
        activeFacility.value.organization.code.startsWith('edgeworks')
    )
}

export function useOrgShortTitle() {
    const activeFacility = useActiveFacility()

    return computed(() => activeFacility.value.organization.shortName)
}

export function useBookingIdName() {
    const activeFacility = useActiveFacility()

    return computed(() => activeFacility.value.organization.bookingIdName)
}
